import { render, staticRenderFns } from "./BriefingComponent.vue?vue&type=template&id=163825ad&scoped=true&"
import script from "./BriefingComponent.vue?vue&type=script&lang=ts&"
export * from "./BriefingComponent.vue?vue&type=script&lang=ts&"
import style0 from "./BriefingComponent.vue?vue&type=style&index=0&id=163825ad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163825ad",
  null
  
)

export default component.exports